aside {
  background-color: #4d44b5 !important;
}

.errorClass{
  border-color: 1px solid #4d44b5 !important;
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingMain {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none !important;
}

/* =================login form======================== */
.loginCont {
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.loginMain {
  width: 40%;
  height: 60%;
  background-color: white;
  margin: auto;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginPage {
  width: 60%;
}
.loginPage h2 {
  text-align: center;
  margin: 10px;
}
.loginPage dt {
  margin-bottom: 10px;
}
.loginPage .input {
  width: 80%;
  padding: 10px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #9cd129;
}
.loginBtn {
  margin-top: 10%;
}
.loginBtn button {
  padding: 2%;
  width: 40%;
  border: none;
  background: #9cd129;
  color: white;
  border-radius: 10px;
  outline: transparent;
}
.loginBtn button:hover {
  background-color: black;
}

/*================================= Add Drugs ====================*/
.AddContainer {
  background: #f4f6f9;
  padding: 5px;
}
.AddContainer h2 {
  margin-bottom: 40px;
  margin-left: 20px;
}

/*============================== Add products======================= */

.productContainer {
  background: #f4f6f9;
  padding: 5px;
}
.productContainer h2 {
  margin-bottom: 40px;
  margin-left: 20px;
}

.checkbocProduct {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.checkbocProduct input {
  margin: 10px;
}
.categorytag {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.categorytag input {
  margin: 10px;
  padding: 2px;
}
.errorCont {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* ==========================nav hover========================== */
nav ul li p {
  font-size: 16px;
  font-weight: 500;
}
.nav-link span {
  font-size: 20px;
  margin-right: 5px;
}
.arrowsize {
  margin-top: 7px !important;
}
.brand-text {
  font-size: 22px;
  font-weight: 600 !important;
}
.headerDesign {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  height: 100%;
}
.headerDesign li {
  list-style: none;
  margin-right: 20px;
}
.headerDesign li span {
  font-size: 25px;
  cursor: pointer;
  color: #051937;
}
.productDesign {
  margin-left: 15px;
}
.kjhTags {
  margin-left: 10px;
}
.errorhandle {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.errorlabel {
  color: tomato;
  margin-top: -5px;
  font-size: 12px;
}
.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1 {
  width: 100%;
  background: rgba(0, 0, 0, 0);
}
.box2 {
  width: 100%;
  background: rgba(0, 0, 0, 0);
}
.aguserid {
  background: #00bf72;
  height: 7vh;
  border-radius: 4px;
}
.ageidbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #f4f6f9;
}
.agedivigm {
  margin-left: 35%;
  text-align: center;
}
.agedivigm .agentimg {
  width: 30vh;
  background: #f8f9fa;
  height: 30vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.agentimg img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.checkbox-wrapper-6 .tgl {
  display: none;
}

.checkbox-wrapper-6 .tgl,
.checkbox-wrapper-6 .tgl:after,
.checkbox-wrapper-6 .tgl:before,
.checkbox-wrapper-6 .tgl *,
.checkbox-wrapper-6 .tgl *:after,
.checkbox-wrapper-6 .tgl *:before,
.checkbox-wrapper-6 .tgl + .tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-6 .tgl::-moz-selection,
.checkbox-wrapper-6 .tgl:after::-moz-selection,
.checkbox-wrapper-6 .tgl:before::-moz-selection,
.checkbox-wrapper-6 .tgl *::-moz-selection,
.checkbox-wrapper-6 .tgl *:after::-moz-selection,
.checkbox-wrapper-6 .tgl *:before::-moz-selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-6 .tgl::selection,
.checkbox-wrapper-6 .tgl:after::selection,
.checkbox-wrapper-6 .tgl:before::selection,
.checkbox-wrapper-6 .tgl *::selection,
.checkbox-wrapper-6 .tgl *:after::selection,
.checkbox-wrapper-6 .tgl *:before::selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-6 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:after,
.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  display: none;
}

.checkbox-wrapper-6 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-6 .tgl-light + .tgl-btn {
  background: #6c757d;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}

.checkbox-wrapper-6 .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}

.checkbox-wrapper-6 .tgl-light:checked + .tgl-btn {
  background: #0da487;
}



/* ============================ media query =========================*/
@media screen and (max-width: 1400px) {
  .loginPage {
    width: 75%;
  }
  .loginMain {
    width: 45%;
  }
}
@media screen and (max-width: 1200px) {
  .loginPage {
    width: 70%;
  }
  .loginMain {
    width: 42%;
  }
}

@media screen and (max-width: 1000px) {
  .loginPage {
    width: 70%;
  }
  .loginMain {
    width: 55%;
  }
}
@media screen and (max-width: 800px) {
  .loginPage {
    width: 85%;
  }
  .loginMain {
    width: 65%;
  }
  .categoryMain .categoryMain1 {
    width: 95%;
  }
  .categoryMain .categoryMain2 {
    width: 95%;
  }
}
@media screen and (max-width: 500px) {
  .loginPage {
    width: 90%;
  }
  .loginMain {
    width: 90%;
  }
  .categoryMain .categoryMain1 {
    width: 95%;
  }
  .categoryMain .categoryMain2 {
    width: 95%;
  }
  .ageinfo {
    flex-direction: column;
  }
}
@media screen and (max-width: 350px) {
  .loginPage {
    width: 80%;
  }
  .loginMain {
    width: 90%;
  }
  .ageinfo {
    flex-direction: column;
  }
}

.dropbtn {
  /* background-color: #04AA6D; */
  color: none;
  /* padding: 16px;
  font-size: 16px; */
  border: none;
  background-color: transparent;
  font-size: 13px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: none;
}
